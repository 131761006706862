import { gql, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { Customization } from "../../types/Customization";
import { Payload } from "../../types/Payload";
import "moment/locale/fr";
import moment from "moment";
import useWebSocket from 'react-use-websocket';
import { socketUrl } from "../../constants/socketUrl";
import { SelectStatus } from "../../components/SelectStatus";
import { OrderStatusPill } from "../../components/OrderStatusPill";
import { ChangeOrderStatus } from "../../components/ChangeOrdersStatus";
import { PrintOrders } from "../../components/PrintOrders";
import { PairsInQueue } from "../../components/PairsInQueue";

const ORDERS = gql`
   query ($status: [String!], $ordersId: Float, $page:Float) {
    orders(status: $status, id: $ordersId, page: $page) {
        id
        createdAt
        status
        payload
    }
    }
`;

export const Orders = () => {
    const [searchId, setSearchId] = useState<string | null>();
    const [selectedStatus, setSelectedStatus] = useState<string[]>(["TO_PRODUCE", "IN_PRODUCTION"]);
    const [selectedOrders, setSelectedOrders] = useState<string[]>([]);
    const [clearSelectedOrders, setClearSelectedOrders] = useState<boolean>(false);
    const [page, setPage] = useState<number>(1);
    const { data, loading, error, refetch } = useQuery(ORDERS, {
        variables: {
            status: selectedStatus,
            ordersId: Number(searchId),
            page
        },
        fetchPolicy: "no-cache"
    });

    const [orders, setOrders] = useState([]);
    const { lastMessage } = useWebSocket(socketUrl);

    useEffect(() => {
        if (clearSelectedOrders) {
            setSelectedOrders([]);
            setClearSelectedOrders(false);
        }
    }, [clearSelectedOrders])

    useEffect(() => {
        if (data) {
            setOrders(data.orders.map((order: any) => ({
                ...order,
                payload: JSON.parse(order?.payload)
            })));
        }
    }, [data]);


    useEffect(() => {
        if (lastMessage) {
            const parsedData = JSON.parse(lastMessage.data.toString());
            if (parsedData.type === 'order') {
                refetch();
            }
        }
    }, [lastMessage, refetch]);


    return (
        <div className="px-3">
            <h1 className="font-semibold text-lg">Commandes</h1>
            <div className="flex">
                <input value={searchId || undefined} onChange={e => {
                    setSearchId(e.target.value);
                    if (e.target.value === "") {
                        setSearchId(undefined);
                    }
                }} className="w-full mr-5 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5" type="text" placeholder="Rechercher une commande par ID" />
            </div>
            <div className="mt-4 flex gap-3">
                <PrintOrders selectedOrdersIds={selectedOrders} refetch={refetch} />
                <div className="flex-1">
                    <ChangeOrderStatus selectedOrdersIds={selectedOrders} refetch={refetch} setClearSelectedOrders={setClearSelectedOrders} />
                </div>
            </div>
            <div className="mt-3">
                <SelectStatus
                    selectedStatus={selectedStatus}
                    setSelectedStatus={setSelectedStatus}
                />
            </div>
            <div>
                {<p className="font-semibold">Nombre de commande{selectedOrders.length > 1 ? "s" : ""} sélectionnée{selectedOrders.length > 1 ? "s" : ""} : {selectedOrders.length}</p>}
                <PairsInQueue orders={orders} />
            </div>
            <div className="flex items-center">
                {/* nxt page, previous page, page  */}
                <button onClick={() => { if (page > 1) setPage(page - 1) }} className="text-blue-600 underline font-bold py-2 px-4 rounded">
                    Page précédente
                </button>
                <p>Page {page}</p>
                <button onClick={() => setPage(page + 1)} className="text-blue-600 underline font-bold py-2 px-4 rounded">
                    Page suivante
                </button>

            </div>
            <div className="mt-5">
                {loading && <p>Chargement des commandes</p>}
                {error && <p>Erreur lors du chargement des commandes</p>}
                {!loading && orders.length === 0 && <p>Aucune commande</p>}
                {!loading && orders.length > 0 && (
                    <table className="w-full">
                        <thead>
                            <tr>
                                <th className="border border-gray-300">ID</th>
                                <th className="border border-gray-300">Date de création</th>
                                <th className="border border-gray-300">Contenu</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((order: any) => {

                                let isOrderSelected = selectedOrders.includes(order.id);
                                return (
                                    <tr key={order.id}
                                        className={`cursor-pointer ${isOrderSelected ? "bg-slate-200" : "bg-white"}`}
                                        onClick={() => {
                                            if (isOrderSelected) {
                                                setSelectedOrders(selectedOrders.filter(selectedOrder => selectedOrder !== order.id))
                                                return;
                                            }
                                            setSelectedOrders([...selectedOrders, order.id])
                                        }}>
                                        <td className="border border-gray-300">
                                            <div className="flex flex-col items-center">
                                                <p>
                                                    {order.id}
                                                </p>
                                                <OrderStatusPill status={order.status} />
                                            </div>
                                        </td>
                                        <td className="border border-gray-300">
                                            <div className="flex flex-col items-center">{
                                                moment(order.createdAt).format("DD/MM/YYYY HH:mm")
                                            }
                                            </div>
                                        </td>
                                        <td className="border border-gray-300 flex gap-5 p-3">
                                            <div className="flex justify-around">
                                                {order.payload.map((product: Payload) => {
                                                    return (
                                                        <div key={product.templateCode} className="border p-3 rounded-lg">
                                                            <h2 className="font-semibold text-lg">{product.templateCode}</h2>
                                                            <p>Quantité: {product.quantity}</p>
                                                            <p>Taille: {product.size}</p>
                                                            {product.fields.map((field: Customization) => {
                                                                return (
                                                                    <div key={field.key} className="mb-1">
                                                                        <p>{field.value}</p>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                )}
            </div>
        </div>
    )
};